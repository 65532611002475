import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { tableCustomStyles } from "../../../../helperFuncs";
import MeterInfoModal from "../../../Meter/modals/MeterInfoModal";
import {
  useUnassignMeterMutation,
  useUnassignRFMeterMutation,
} from "../../../../services/meter.services";
import { AppLoader } from "../../../AppLoader";
import { errorBlock } from "../../../../controllers/errorBlock";

const AllCustomerTable = ({ data = [], setdetails }) => {
  const [selectedId, setSelectedId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [editing, setediting] = useState(false);

  const [
    unassignMeter,
    { isLoading: unassignLoading, error: unassignError, data: unassignData },
  ] = useUnassignMeterMutation();

  const [
    unassignRFMeter,
    {
      isLoading: unassignRFLoading,
      error: unassignRFError,
      data: unassignRFData,
    },
  ] = useUnassignRFMeterMutation();
  let num = 1;

  const handleUnassign = (item) => {
    try {
      const customerMetertype = item.metermodel.split("-")[1];
      console.log(customerMetertype);

      if (customerMetertype == "XG") {
        unassignMeter({
          meternumber: item?.meter,
          customerid: item?.customerid,
          address: item?.address,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      } else if (customerMetertype == "RF") {
        // console.log(item);
        unassignRFMeter({
          meternumber: item?.meter,
          customerid: item?.customerid,
          address: item?.address,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  const columns = [
    {
      name: "Username",
      selector: (row) => row.customerid,
      center: true,
      grow: 3,
    },
    {
      name: "Full name",
      selector: (row) => row.firstname,
      center: true,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="">
              {`${item?.firstname} ${item.lastname}` ?? "-Nil-"}
            </div>
          </div>
        );
      },
    },
    {
      name: "Meter number",
      center: true,
      selector: (row) => row.meter,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <Link
              className="underline"
              to={`/meter-details/${item?.customerid}/${item?.meter}`}
            >
              {item?.meter}
            </Link>
          </div>
        );
      },
    },
    {
      name: "Assigned by",
      center: true,
      selector: (row) => row.assigner,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="">{item?.assigner ?? "-Nil-"}</div>
          </div>
        );
      },
    },
    {
      name: "Customer type",
      center: true,
      selector: (row) => row.customertype,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return <div className="">{item?.customertype ?? "-Nil-"}</div>;
      },
    },
    // {
    //   name: "Pole number",
    //   center: true,
    //   selector: (row) => row.polenumber,
    //   sortable: true,
    //   grow: 2,
    //   cell: (item) => {
    //     return <div className="">{item?.polenumber ?? "-Nil-"}</div>;
    //   },
    // },
    // {
    //   name: "Section",
    //   center: true,
    //   selector: (row) => row.polenumber,
    //   sortable: true,
    //   grow: 2,
    //   cell: (item) => {
    //     return <div className="">{item?.section ?? "-Nil-"}</div>;
    //   },
    // },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="">{moment(item.datecreated).format("lll")}</div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Date assigned",
      center: true,
      selector: (row) => row.datecreated,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="">{moment(item.datecreated).format("lll")}</div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 3,
      center: true,
      cell: (item) => {
        return (
          <div className="flex rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        className="text-[12px]"
                        onClick={() => {
                          setSelectedId(item.meter);
                          setModal(true);
                        }}
                      >
                        View details
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="text-[12px]"
                        onClick={() => {
                          setediting(true);
                          handleUnassign(item);
                        }}
                      >
                        Unassign meter
                      </div>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <Link
                        to={`/salesagent/customer/meter/${item?.meter}/${item?.customerid}`}
                        className="text-[12px]"
                        onClick={() => {}}
                      >
                        View Recharge history
                      </Link>
                    ),
                  },
                ],
              }}
              placement="bottom"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
            {/* <div
              onClick={() => {
                setdetails(item);
                setmodal(true);
              }}
              className="p-1 border-[1px] border-gray-400 rounded-sm mr-3 cursor-pointer"
            >
              View details
            </div> */}
            {/* <Link
             
              className="ml-2 border-[1px] rounded border-secondary  text-secondary font-light p-1"
            >
              
            </Link>{" "} */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-[1700px]">
      {unassignLoading && <AppLoader open={unassignLoading} />}

      {modal && (
        <MeterInfoModal
          setopen={setModal}
          open={modal}
          meterNumber={selectedId}
        />
      )}

      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default AllCustomerTable;
