import moment from "moment";
import { useState } from "react";

import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { tableCustomStyles } from "../../../../helperFuncs";
import { Empty } from "antd";

const MeterCommandsTable = ({ data }) => {
  const [loading, setloading] = useState(null);

  const userDetials = useSelector((state) => state.userDetials);

  const columns = [
    {
      name: "Meter no",
      center: true,
      selector: (row) => row.meternumber,
      grow: 2,
      sortable: true,
    },
    {
      name: "Batch No",
      center: true,
      selector: (row) => row.batchid,
      grow: 2,
      sortable: true,
    },
    {
      name: "Serial no",
      center: true,
      selector: (row) => row.device_serial,
      grow: 2,
      sortable: true,
    },

    {
      name: "Meter type",
      center: true,
      selector: (row) => row.type,
      grow: 1,
      sortable: true,
    },
    {
      name: "Phases",
      center: true,
      selector: (row) => row.phases,
      grow: 1,
      sortable: true,
    },
    {
      name: "Registered by",
      center: true,
      selector: (row) => row.registrar,
      grow: 1,
      sortable: true,
    },
    {
      name: "Assigned to",
      center: true,
      selector: (row) => row.assignedto,
      grow: 1,
      sortable: true,
    },

    {
      name: "Load limit",
      center: true,
      selector: (row) => row.loadlimit,
      grow: 1,
      sortable: true,
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: userDetials?.role != "Project Manager" ? 2 : 4,
      center: true,
    },
  ];

  return (
    <div className="border-t-[1px]">
      <DataTable
        selectableRows
        columns={columns}
        data={data}
        noDataComponent={
          <div className="h-[300px] flex flex-col items-center justify-center">
            <Empty description="No command has been recorded on meter" />
          </div>
        }
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default MeterCommandsTable;
