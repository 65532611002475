import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RevenueChart from "../../Dashboards/SystemAdmin/Chart/RevenueChart";
import DateRangePicker from "../../DateRangePicker";
import { useGetProjectManagerRevenueMutation } from "../../../services/revenue.services";
import Layout from "../../Layout";
import WalletCard from "../../Wallet/Cards/WalletCards";
import { currencyOptions } from "../../../screens/Wallet/walletData";
import { RiArrowDownLine } from "react-icons/ri";
import Rule from "../../Rule/Index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CustomButton } from "../../CustomButton";
import { errorBlock } from "../../../controllers/errorBlock";
import { useGetSiteDetailsMutation } from "../../../services/projectsite.services";
import { countryList } from "../../../helperFuncs/countryList";
import { formatTransactionTitle, formatUnits } from "../../../helperFuncs";

const ProjectSiteRevenueAnalytics = () => {
  const [refresh, setRefresh] = useState(false);
  const { sitename, siteid, country } = useSelector(
    (state) => state.projectSite
  );
  const { username } = useSelector((state) => state.userDetails);

  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD")
  );

  const [currency, setcurrency] = useState(
    countryList[country]["Currency Code"]
  );

  useEffect(() => {
    if (country) {
      // setcurrency(countryList[country]["Currency Code"]);
    }
  }, []);

  const [tabindex, settabindex] = useState(0);

  const [getProjectManagerRevenue, { isLoading, error, data }] =
    useGetProjectManagerRevenueMutation();

  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const [paymentHistory, setPaymetHistory] = useState([]);
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    getProjectManagerRevenue({
      username,
      startdate,
      enddate,
    })
      .then((res) => {
        console.log(res.data, "response");
      })
      .catch((err) => {
        errorBlock(err);
      });
  }, [refresh]);

  return (
    <Layout>
      <div className="w-100 mt-2 ">
        <div className="bg-white p-2 rounded shadow">
          <div className="flex justify-between my-2">
            <div className="flex">
              <div className="h-[30px] mr-2 w-[20px] rounded bg-sky-200"></div>
              <div className="font-semibold text-[16px] ">
                Cummulative revenue on site {sitename}
              </div>
            </div>

            <DateRangePicker
              setEndDate={setenddate}
              setStartDate={setstartdate}
              refreshBtn={setRefresh}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <WalletCard
              value={data?.cumulativeStats[currency]?.totalRevenue ?? 0}
              title={"Total Revenue"}
              type={"balance"}
              currencyOptions={currencyOptions}
              currency={currency}
              setcurrency={setcurrency}
            />
            <WalletCard
              value={data?.cumulativeStats[currency]?.transactionCount ?? 0}
              title={"Completed transactions"}
              currencyOptions={currencyOptions}
              currency={currency}
              setcurrency={setcurrency}
              type={"counts"}
            />
            <WalletCard
              value={data?.cumulativeStats[currency]?.totalCommission ?? 0}
              title={"Paid out commissions"}
              type={"spent"}
              currencyOptions={currencyOptions}
              currency={currency}
              setcurrency={setcurrency}
            />
            <WalletCard
              value={data?.cumulativeStats[currency]?.totalKWh ?? 0}
              title={"Total units sold (kWh)"}
              type={"units"}
              currencyOptions={currencyOptions}
              currency={currency}
              setcurrency={setcurrency}
            />
          </div>
        </div>

        {/* payments methods */}
        <div className="bg-white p-2 mt-3 rounded shadow">
          <div className="flex my-2 justify-between items-center">
            <div className="">
              <div className="font-semibold text-[16px] ">
                Revenue by payment methods
              </div>
              <div className="text-gray-600">
                Stats of revenue generate from different payment methods
              </div>
            </div>
          </div>
          <Rule />
          {!data?.gatewayStats ? (
            <div className="">No site stats</div>
          ) : Object.keys(data?.gatewayStats).length == 0 ? (
            <div className=" text-center p-10">
              No sales made within the selected date range. Select a wider date
              range{" "}
            </div>
          ) : (
            Object.keys(data?.gatewayStats).map((i, index) => (
              <div className="" key={index}>
                <div className="flex my-2 justify-between bg-gray-100 p-2 items-center">
                  <div className="font-semibold ">
                    {i[0].toUpperCase() + i.substring(1)}
                  </div>
                  <CustomButton onClick={() => settabindex(index)}>
                    {tabindex == index ? (
                      <IoIosArrowDown size={18} />
                    ) : (
                      <IoIosArrowUp size={18} />
                    )}
                  </CustomButton>
                </div>
                {data?.gatewayStats ? (
                  <div className="">
                    {tabindex == index && (
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                        {Object.keys(data?.gatewayStats[i][currency]).map(
                          (value) => (
                            <WalletCard
                              value={
                                data?.gatewayStats[i][currency][value] ?? 0
                              }
                              title={formatTransactionTitle(value)}
                              type={formatUnits(value)}
                              currencyOptions={currencyOptions}
                              currency={currency}
                              setcurrency={setcurrency}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  "No items"
                )}
              </div>
            ))
          )}
        </div>

        {/* Agent stats */}
        <div className="bg-white p-2 mt-3 rounded shadow">
          <div className="flex my-2 justify-between items-center">
            <div className="">
              <div className="font-semibold text-[16px] ">
                Revenue by sales agents
              </div>
              <div className="text-gray-600">
                Stats of revenue generate from different payment methods
              </div>
            </div>
          </div>
          <Rule />
          {!data?.agentStats ? (
            <div className="">No site stats</div>
          ) : Object.keys(data?.agentStats).length == 0 ? (
            <div className=" text-center p-10">
              No sales made within the selected date range. Select a wider date
              range{" "}
            </div>
          ) : (
            Object.keys(data?.agentStats).map((i, index) => (
              <div className="" key={index}>
                <div className="flex my-2 justify-between bg-gray-100 p-2 items-center">
                  <div className="font-semibold ">
                    {i[0].toUpperCase() + i.substring(1)}
                  </div>
                  <CustomButton onClick={() => settabindex(index)}>
                    {tabindex == index ? (
                      <IoIosArrowDown size={18} />
                    ) : (
                      <IoIosArrowUp size={18} />
                    )}
                  </CustomButton>
                </div>
                {data?.agentStats ? (
                  <div className="">
                    {tabindex == index && (
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                        {Object.keys(data?.agentStats[i][currency]).map(
                          (value) => (
                            <WalletCard
                              value={data?.agentStats[i][currency][value] ?? 0}
                              title={formatTransactionTitle(value)}
                              type={formatUnits(value)}
                              currencyOptions={currencyOptions}
                              currency={currency}
                              setcurrency={setcurrency}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  "No items"
                )}
              </div>
            ))
          )}
        </div>

        <div className="bg-white col-span-2 p-2 mt-3 shadow-md rounded-md">
          <div className="flex  h-[40px] border-b-1 border-gray-400  items-start justify-between">
            <div className="">
              <div className="font-semibold text-[16px]">Revenue</div>
              <div className="text-gray-600">
                Revenue generated on site from{" "}
              </div>
            </div>
            <div className="">
              <div className="h-[500px]"></div>
            </div>
          </div>
          <div className="h-[80vh]">
            <RevenueChart data={[]} height={550} />
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default ProjectSiteRevenueAnalytics;
