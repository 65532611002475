import React, { useState } from "react";
import Rule from "../../../../components/Rule/Index";
import { Tabs, Input, Button } from "antd";
import { controlsData } from "./data";
import { errorBlock } from "../../../../controllers/errorBlock";
import {
  useResetSAIDIMutation,
  useResetSAIFIMutation,
} from "../../../../services/commands.services";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
export const CustomerMeterControls = () => {
  const { customer, meterid } = useParams();

  const [
    resetSAIFI,
    { isloading: saidiloading, data: saidiData, error: saidiError },
  ] = useResetSAIFIMutation();

  const [
    resetSAIDI,
    { isloading: saifiloading, data: saifiData, error: saifiError },
  ] = useResetSAIDIMutation();

  const handleCommand = (type) => {
    try {
      switch (type) {
        case "SAIDI":
          resetSAIDI({
            meterid,
          })
            .then((res) => {
              if (res.error) {
                errorBlock(res.error);
                return;
              }
              if (res.data.response !== "success") {
                toast.error(res.data.error);
                return;
              }
              toast.success(res.data.response);
            })
            .catch((err) => {
              errorBlock(err);
            });
          break;
        default:
          break;
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  const modalState = () => {};

  const loadingState = (type) => {
    switch (type) {
      case "RSTO3":
        return saifiloading;
      default:
        break;
    }
  };

  const EnquiryCommands = () => {
    return (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.enquiryData.map(({ id, label, short, description }) => (
            <div key={id} className="border p-2 h-auto rounded-md">
              <h1 className="m-0">{label}</h1>
              <p className="font-light">{description}</p>
              <Button
                loading={loadingState(short)}
                onClick={() => handleCommand(short)}
                className="!bg-transparent !text-black w-full !rounded-md !outline-none !border-black"
              >
                Set {short}
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const HardwareCommands = () => {
    return (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.hardwareCommands.map(
            ({ id, label, short, description }) => (
              <div
                key={id}
                className="border flex flex-col justify-between h-[130px] p-2 rounded-md"
              >
                <div className="">
                  <h1 className="m-0">{label + " (" + short + ")"} </h1>
                  <p className="font-light">{description}</p>
                </div>
                <Button className="!bg-gray-900 !text-white w-full !rounded-md !outline-none !border-none">
                  {short == "GTP" ? "Get" : "Clear"} {short}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const SettingsCommands = () => {
    return (
      <div className="">
        <div className="flex mb-3 items-center justify-end">
          <Input.Search
            className="!w-[250px]"
            placeholder="search of command"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {controlsData.hardwareCommands.map(
            ({ id, label, short, description }) => (
              <div
                key={id}
                className="border flex flex-col justify-between h-[130px] p-2 rounded-md"
              >
                <div className="">
                  <h1 className="m-0">{label + " (" + short + ")"} </h1>
                  <p className="font-light">{description}</p>
                </div>
                <Button className="!bg-gray-900 !text-white w-full !rounded-md !outline-none !border-none">
                  {short == "GTP" ? "Get" : "Clear"} {short}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const commandList = [
    {
      id: 1,
      label: "Enquiry Controls",
      children: <EnquiryCommands />,
    },
    {
      id: 2,
      label: "Settings Controls",
      children: <EnquiryCommands />,
    },
    {
      id: 3,
      label: "Hardware Controls",
      children: <HardwareCommands />,
    },
  ];
  const [tabindex, settabindex] = useState(1);

  return (
    <div className="">
      {/* <h1>lorem</h1> */}
      <div className="mt-4">
        <Tabs
          defaultActiveKey={tabindex}
          tabPosition={"left"}
          style={{
            height: 220,
          }}
          onChange={(key) => settabindex(key)} // Update active tab index
          items={commandList.map((i) => {
            const isActive = tabindex === i.id;
            return {
              label: (
                <div
                  className={`font-light ${
                    isActive ? "text-black font-bold" : "text-gray-600"
                  }`}
                >
                  {i.label}
                </div>
              ),
              key: i.id,
              children: i.children,
            };
          })}
        />
        <div className=" h-[800px] hidden grid-cols-5 gap-4">
          <div className="col-span-1 border"></div>
          <div className=" col-span-4 border"></div>
        </div>
      </div>
    </div>
  );
};
