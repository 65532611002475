import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const analyticAPI = createApi({
  reducerPath: "analyticPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers) => {
      // Add additional header preparations here if necessary
    },
  }),
  endpoints: (build) => ({
    getEnergyAnalyticsSummary: build.mutation({
      query: (data) => ({
        url: "energyanalytics/consumer/getenergyanalyticssummaryformeters",
        method: "POST",
        body: {
          ...credentials,
          meterjsonarray: data?.meterjsonarray, // JSON array of meter data
          startdate: data?.startdate, // e.g., "2024-12-01 00:00:00"
          enddate: data?.enddate, // e.g., "2024-12-31 23:59:59"
        },
      }),
    }),
  }),
});

export const { useGetEnergyAnalyticsSummaryMutation } = analyticAPI;
