import axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import BackBtn from "../../../components/BackBtn";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Skimmer from "../../../components/Loader/Skimmer";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Rule from "../../../components/Rule/Index";
import moment from "moment";
import TableSkimmer from "../../../components/TableSkimmer";
import VendingHistoryTable from "../../../components/SalesAgent/Table/VendingHistoryTable";
import { Button } from "../../../components/Button";
import TopUpModal from "../../../components/SalesAgent/Modals/TopUpModal";
import { useGetCustomerMeterVendHistoryMutation } from "../../../services/salesanalytics.services";
import { errorBlock } from "../../../controllers/errorBlock";
import FilterDateRange from "../../../components/FilterDateRange";

const CustomerMeterRecharge = () => {
  const { customer, meterid } = useParams();
  const [modal, setmodal] = useState(false);
  const [showdatefilter, setshowdatefilter] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [logData, setlogData] = useState([]);
  const [meterDetails, setMeterDetails] = useState({});
  const [rechargeHistory, setRechargeHistory] = useState([]);
  const [loading, setLoading] = useState({
    meter: true,
    profile: true,
    log: false,
  });
  const controller = new AbortController();

  const [getCustomerMeterVendHistory, { isloading, data, error }] =
    useGetCustomerMeterVendHistoryMutation();
  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  useEffect(() => {
    getCustomerMeterVendHistory({ meterid, startdate, enddate }).unwrap();
  }, [refresh]);

  useEffect(() => {
    axios
      .post("/auth/useraccount/searchuser", { keyword: customer })
      .then((response) => {
        // console.log(response.data, "response");
        setUserDetails(response.data.body[0]);
        setLoading((prev) => {
          return {
            ...prev,
            profile: false,
          };
        });
      })
      .catch((err) => {
        toast(err.message);
        setLoading((prev) => {
          return {
            ...prev,
            profile: false,
          };
        });
      });

    axios
      .post("/meter/getmeter", { meternumber: meterid })
      .then((response) => {
        setMeterDetails(response.data);
        setLoading((prev) => {
          return {
            ...prev,
            meter: false,
          };
        });
      })
      .catch((err) => {
        toast(err.message);
        setLoading((prev) => {
          return {
            ...prev,
            meter: false,
          };
        });
      });

    // getRechargeHistory();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (error) {
      errorBlock(error);
    }
  }, [error]);

  const handlefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(moment(Date.now()).startOf("day").format("YYYY-MM-DD"));
        break;
      case "week":
        setStartDate(moment(Date.now()).startOf("week").format("YYYY-MM-DD"));
        break;
      case "month":
        setStartDate(moment(Date.now()).startOf("month").format("YYYY-MM-DD"));
        break;
      case "year":
        setStartDate(moment(Date.now()).startOf("year").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD"));
    setrefresh((prev) => !prev);
  };

  return (
    <Layout>
      {modal && (
        <TopUpModal open={modal} setopen={setmodal} refreshBtn={setrefresh} />
      )}
      <div className="mb-10">
        <BackBtn />
        <div className="bg-white rounded-md drop-shadow-md">
          <div className="p-3 flex justify-between items-center">
            {/* <div className="font-semibold text-[16px]">
              Customer meter details
            </div> */}
            <FilterDateRange
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              handlefilter={handlefilter}
              open={showdatefilter}
              setRefresh={setrefresh}
              setopen={setshowdatefilter}
            />
          </div>
          <div className="grid grid-cols-2 ">
            <div className="p-3 border-r-[0.8px] border-gray-200">
              <div className="">
                <div className="font-semibold">Customer profile details</div>
              </div>
              <Rule />

              <div className="">
                {loading.profile ? (
                  Array.from(Array(4)).map((i, index) => (
                    <div className="my-2" key={index}>
                      <Skimmer heigth={"30px"} />
                    </div>
                  ))
                ) : (
                  <>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Full name:</div>
                      <div className="font-semibold text-gray-800">
                        {userDetails?.firstname
                          ? userDetails?.firstname + " " + userDetails?.lastname
                          : userDetails?.username}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Username:</div>
                      <div className="font-semibold text-gray-800">
                        {customer ?? "-Nil-"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Email address:</div>
                      <div className="font-semibold text-gray-800">
                        {userDetails?.email ?? "-Nil-"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Phone number:</div>
                      <div className="font-semibold text-gray-800">
                        {userDetails?.phone ?? "-Nil-"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Account status:</div>
                      <div
                        className={`font-semibold ${
                          userDetails?.activestatus == "active"
                            ? "text-green-600"
                            : "text-red-500"
                        }`}
                      >
                        {userDetails?.activestatus ?? "-Nil-"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Date registered:</div>
                      <div className={`font-semibold text-gray-800`}>
                        {moment(userDetails?.dateregistered).format("lll")}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="p-3">
              <div className="flex items-center justify-between">
                <div className="font-semibold">Meter details</div>
                <div className="font-semibold text-secondary">{meterid}</div>
              </div>
              <Rule />
              <div className="">
                {loading.meter ? (
                  Array.from(Array(4)).map((i, index) => (
                    <div className="my-2" key={index}>
                      <Skimmer heigth={"30px"} />
                    </div>
                  ))
                ) : (
                  <>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Load limit:</div>
                      <div className="font-semibold text-gray-800">
                        {meterDetails.loadlimit ?? 0}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Phase:</div>
                      <div className="font-semibold text-gray-800">
                        {meterDetails.phases ?? "-"} phase
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Meter type:</div>
                      <div className="font-semibold" style={{}}>
                        {meterDetails.type ?? "unknown"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Registrar:</div>
                      <div className="font-semibold" style={{}}>
                        {meterDetails.registrar ?? "unknown"}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Date registered:</div>
                      <div className="font-semibold text-gray-800">
                        {moment(meterDetails.dateregistered).format("lll")}
                      </div>
                    </div>
                    <div className="flex items-center justify-between my-2">
                      <div className="text-gray-700">Date decommissioned:</div>
                      <div className="font-semibold text-gray-800">
                        {meterDetails.datedecommissioned != "null"
                          ? moment(meterDetails.datedecommissioned).format(
                              "lll"
                            )
                          : "-Nil-"}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg drop-shadow-md p-3 h-[80vh] mb-30 mt-2">
          <div className="flex items-center justify-between mb-3">
            <PageTitle
              title={"Recharge history"}
              caption={
                "All recharge history on " +
                customer[0].toUpperCase() +
                customer.substring(1, customer.length) +
                " meter"
              }
            />
            {/* <div className="">
              <div className="w-[120px] ">
                <Button
                  text={"Top up meter"}
                  height={"40px"}
                  onClick={() => setmodal(true)}
                />
              </div>
            </div> */}
          </div>

          <div className="">
            {loading.log ? (
              <TableSkimmer entries={13} col={7} />
            ) : (
              <VendingHistoryTable
                data={data?.purchases ?? []}
                loading={isloading}
                // setdetails={setdetails}
                setmodal={setmodal}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerMeterRecharge;
