import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes, useNavigate, Outlet } from "react-router-dom";
import ResetPassword from "./screens/Auth/ResetPassword";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import CompleteRegistration from "./screens/Auth/CompleteRegistration";
import UserDashboard from "./screens/Dashboard/UserDashboard";
import UserRoles from "./screens/Roles/UserRoles";
import SysAdminDashboard from "./screens/Dashboard/SysAdminDashboard";
import CreateAccount from "./screens/Auth/CreateAccount";
import toast, { Toaster } from "react-hot-toast";
import EmailVerification from "./screens/Auth/EmailVerification";
import SignIn from "./screens/Auth/SignIn";
import SystemAdminList from "./screens/Dashboard/SysAdminDashboard/ZoneRoleList";
import ProjectManagerDetails from "./screens/Dashboard/SysAdminDashboard/ProjectManagerDetails";
import CustomerManagerDetails from "./screens/Dashboard/SysAdminDashboard/CustomerManagerDetails";
import SiteEnergyRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteEnergyRecords";
import SiteRevenueRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteRevenueRecords";
import SiteUsersRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteUsersRecords";
import SiteMeterRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteMeterRecords";
import Users from "./screens/Users";
import NotFound from "./screens/NotFound";

import Customers from "./screens/Customers";
import ProjectSites from "./screens/ProjectManager/ProjectSite";
// import ProjectSiteDashboard from "./screens/ProjectManager/ProjectSite/ProjectSiteDetails";
import AddMeterClass from "./screens/MeterClass/AddMeterClass";

import Settings from "./screens/Settings";
import MeterClass from "./screens/MeterClass";
import CustomerMeterRecharge from "./screens/SalesAgent/CustomerMeterdetails";
import SalesAnalytics from "./screens/SalesAgent/SalesAnalytics";
import Wallet from "./screens/Wallet";
import CustomerManagerDashboard from "./screens/Dashboard/CustomerManagerDashboard";
import ManagerMeter from "./screens/ManageMeter";
import ProjectManagerDashboard from "./screens/Dashboard/ProjectManagerDashboard";
import { getToken } from "./utils/token";
import Notification from "./screens/Notification";
import IncomingRequest from "./screens/IncomingRequest";
import { useSelector } from "react-redux";

import MeterDetails from "./screens/Users/Meter/MeterDetails";
import AllRoles from "./screens/Roles/AllRoles";
import DropDownSettings from "./screens/Settings/DropDownSettings";
import ControlSettings from "./screens/Settings/ControlSetting";
import Meters from "./screens/Meters";
import BatchMeters from "./screens/Meters/BatchMeters";
import ZoneCustomers from "./screens/CustomerManager/ZoneCustomers";
import SalesAgentDashboardSys from "./screens/SalesAgent/SalesAgentDashboardSys";
import SalesAgentDetails from "./screens/SalesAgent/SalesAgentDetails";
import ProjectSiteRevenueAnalytics from "./components/ProjectManager/Charts/ProjectSiteRevenueAnalytics";
import ProjectSiteConsumption from "./screens/ProjectManager/ProjectSite/ProjectSiteConsumption";
import ProjectManagerSalesTopUp from "./screens/ProjectManager/Tabs/ProjectManagerSalesTopUp";
import ProjectSiteOverview from "./screens/ProjectManager/ProjectSite/ProjectSiteOverview";
import ProjectSiteDetails from "./screens/ProjectManager/ProjectSite/ProjectSiteDetails";
import AllProjectSiteLocation from "./screens/ProjectManager/ProjectSite/AllProjectSiteLocation";
import SiteRevenue from "./screens/ProjectManager/ProjectSite/SiteRevenue";
import Discount from "./screens/ProjectManager/Discount";
import PaymentPlan from "./screens/ProjectManager/PaymentPlan";
import ProjectsitGPSelocation from "./screens/ProjectManager/ProjectSite/ProjectsitGPSelocation";
import TieredPlans from "./screens/ProjectManager/TieredPlans";
import PaymentPlanEdit from "./screens/ProjectManager/PaymentPlan/PaymentDetails";
import SalesAgentDashboard from "./screens/SalesAgent/SalesAgentDashboard";
import SiteDCU from "./screens/ProjectManager/SiteDCU";
import DomainRevenue from "./screens/Dashboard/SysAdminDashboard/DomainRevenue";
import CustomerRepDashboard from "./screens/CustomerRepresentative";
import ProjectSiteDCULocation from "./screens/ProjectManager/ProjectSite/ProjectSiteDCULocation";
import { CustomerMeterProfile } from "./screens/Users/Meter/CustomerMeterProfile";
import { CustomerMeterControls } from "./screens/Users/Meter/CustomerMeterControls";
import { CustomerMeterCommands } from "./screens/Users/Meter/CustomerMeterCommands";
import { CustomerMeterAnalytics } from "./screens/Users/Meter/CustomerMeterAnalytics";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/emailverification" element={<EmailVerification />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/completeregistration"
          element={<CompleteRegistration />}
        />
        <Route element={<ProtectedRoute />}>
          {/* System Admin */}
          <Route
            path={"/dashboard/systemadmin/projectsite"}
            element={<ProjectSites />}
          />
          <Route path={"/revenue"} element={<DomainRevenue />} />
          {/* Meters */}
          <Route path={"/allmeters"} element={<Meters />} />
          <Route path={"/meter/batch/:batchid"} element={<BatchMeters />} />
          {/* METER DETAILS */}
          <Route
            path="/meter-details/:customer/:meterid"
            element={<MeterDetails />}
          >
            {/* Nested Routes */}
            {/* <Route path="customerprofile" element={<CustomerMeterProfile />} /> */}
            <Route path="controls" element={<CustomerMeterControls />} />
            <Route path="commands" element={<CustomerMeterCommands />} />
            <Route path="analytics" element={<CustomerMeterAnalytics />} />
          </Route>

          {/* <Route path="/projectsite" element={<ProjectSiteOverview />}>
            <Route
              path="consumption-analytics"
              element={<ProjectSiteConsumption />}
            />
            <Route path="map-location" element={<AllProjectSiteLocation />} />
            <Route path="revenue" element={<SiteRevenue />} />
          </Route> */}

          {/* Meter Class */}
          <Route path={"/meterclass"} element={<MeterClass />} />
          <Route path={"/addmeterclass"} element={<AddMeterClass />} />
          <Route path={"/editmeterclass"} element={<AddMeterClass />} />

          {/* SALES AGENT */}
          <Route
            path="dashboard/salesagent"
            element={<SalesAgentDashboard />}
          />
          <Route
            path="/dashboard/allsalesagent"
            element={<SalesAgentDashboardSys />}
          />
          <Route
            path="/dashboard/salesagent/:siteid/:username"
            element={<SalesAgentDetails />}
          />
          <Route
            path="/dashboard/customermanager/:siteid/:username"
            element={<CustomerManagerDetails />}
          />
          <Route
            path="/dculocation/:dcuid/:siteid"
            element={<ProjectSiteDCULocation />}
          />
          {/* ProjectSiteDCULocation */}
          {/* Project Manager */}
          {/* PROJECT SITE OVERVIEW */}
          <Route path="/projectsite" element={<ProjectSiteOverview />}>
            <Route
              path="consumption-analytics"
              element={<ProjectSiteConsumption />}
            />
            <Route path="map-location" element={<AllProjectSiteLocation />} />
            <Route path="revenue" element={<SiteRevenue />} />
          </Route>
          {/* Site details */}
          <Route
            path={"/dashboard/projectsite/:siteid"}
            element={<ProjectSiteDetails />}
          />
          {/* PROJECT MANAGER */}
          <Route
            path="projectmanager/revenue"
            element={<ProjectSiteRevenueAnalytics />}
          />
          <Route path="/discount" element={<Discount />}></Route>
          <Route
            path="/dashboard/projectmanager"
            element={<ProjectManagerDashboard />}
          >
            <Route
              path="consumption-analytics"
              element={<ProjectSiteConsumption />}
            />
          </Route>
          {/* SITE DCU */}
          <Route path="/dcu" element={<SiteDCU />} />
          <Route path="topup-history" element={<ProjectManagerSalesTopUp />} />
          {/* PAYMENT PLAN */}
          <Route path="/paymentplan" element={<PaymentPlan />} />
          <Route
            path="/paymentplan/edit/:planid/:customerclass"
            element={<PaymentPlanEdit />}
          />
          <Route path="/tierplans" element={<TieredPlans />} />
          <Route
            path="/projectsite/location"
            element={<ProjectsitGPSelocation />}
          />
          {/* SALES AGENT */}
          <Route path="/salesagent/customer" element={<ManagerMeter />} />
          <Route path="/salesagent/revenue" element={<SalesAnalytics />} />
          <Route
            path="/salesagent/customer/meter/:meterid/:customer"
            element={<CustomerMeterRecharge />}
          />
          {/* Customer Representative */}
          <Route
            path={"/dashboard/customerrepresentative"}
            element={<CustomerRepDashboard />}
          />
          {/* CUSTOMER MANAGER */}
          <Route
            path={"/dashboard/customermanager"}
            element={<CustomerManagerDashboard />}
          />
          <Route path="/manage-meter" element={<ManagerMeter />} />
          <Route path="/zonecustomers" element={<ZoneCustomers />} />
          {/* system admin */}
          <Route
            path="/dashboard/systemadmin"
            element={<SysAdminDashboard />}
          />
          <Route
            // path={`/dashboard/system-admin/roles`}
            path={`/userroles/projectzone`}
            element={<SystemAdminList />}
          />
          <Route
            path={"/dashboard/projectsiteinfo/:projectsite/:username"}
            element={<ProjectManagerDetails />}
          />
          {/* Records */}
          <Route
            path={"/dashboard/records/energy/:projectsite/:id"}
            element={<SiteEnergyRecords />}
          />
          <Route
            path={"/dashboard/records/revenue/:projectsite/:id"}
            element={<SiteRevenueRecords />}
          />
          <Route
            path={"/dashboard/records/users/:projectsite/:id"}
            element={<SiteUsersRecords />}
          />
          <Route
            path={"/dashboard/records/meters/:projectsite/:id"}
            element={<SiteMeterRecords />}
          />
          <Route path={"/dashboard/customers"} element={<Customers />} />
          <Route path={"/users"} element={<Users />} />
          {/* user roles */}
          <Route path={"/userroles"} element={<UserRoles />} />
          <Route path={"/roles/alluserroles"} element={<AllRoles />} />
          {/* Settings */}
          <Route path="/settings/profile" element={<Settings />} />
          <Route path="settings/controls" element={<ControlSettings />} />
          <Route path={"/settings/dropdown"} element={<DropDownSettings />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/request" element={<IncomingRequest />} />
          <Route path="/wallet" element={<Wallet />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
}

export default App;

const ProtectedRoute = () => {
  const [userToken, setUserToken] = useState(false);
  const [loading, setloading] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      try {
        let token = await getToken("spiral_token");
        if (!(token && isLoggedIn)) {
          setUserToken(false);
          setloading(false);
          return;
        }
        setUserToken(true);
        setloading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  if (loading) return <div className="">fetching resource....</div>;
  return userToken ? <Outlet /> : <Navigate to="/" />;
};
