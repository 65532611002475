import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { Empty, Select } from "antd";
import DataTable, { TableColumn } from "react-data-table-component";
import EmptyState from "../../../EmptyState";
import { VscCopy } from "react-icons/vsc";
import { tableCustomStyles } from "../../../../helperFuncs";

const VendingHistoryTable = ({ data = [], setmodal, loading }) => {
  const columns = [
    {
      name: "Amount paid",
      selector: (row) => row.amount,
      center: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            {Number(item?.amount).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      name: "Initiated by",
      center: true,
      selector: (row) => row.initiatedBy,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">{item?.initiatedBy ?? "-"}</div>
          </div>
        );
      },
    },
    {
      name: "Plan name",
      center: true,
      selector: (row) => row.planAlias,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px] text-center ">{item?.planAlias}</div>
          </div>
        );
      },
    },
    {
      name: "Unit assigned",
      center: true,
      selector: (row) => row.description,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {" "}
              {Number(item?.kwh).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        );
      },
    },
    {
      name: "Payment gateway",
      center: true,
      selector: (row) => row.paymentGateway,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">{item?.paymentGateway ?? "-"}</div>
          </div>
        );
      },
    },
    {
      name: "Status",
      center: true,
      selector: (row) => row.orderStatus,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">{item?.orderStatus}</div>
          </div>
        );
      },
    },
    {
      name: "Date initiated",
      center: true,
      selector: (row) => row.orderDate,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {moment(item.orderDate).format("lll") ?? "-"}
            </div>
          </div>
        );
      },
      sortable: true,
    },
  ];
  return (
    <DataTable
      style={{
        height: 400,
      }}
      noDataComponent={
        <div className="h-[300px] flex flex-col items-center justify-center">
          <Empty description="No vending data has been recorded on meter" />
        </div>
      }
      columns={columns}
      data={data}
      pagination
      progressPending={loading}
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default VendingHistoryTable;
