import moment from "moment";
import DateRangePicker from "../../../../components/DateRangePicker";
import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import BackBtn from "../../../../components/BackBtn";
import { useNavigate, useParams } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import { Button, Switch, Tabs, Tooltip } from "antd";
import { Outlet } from "react-router-dom";
import { IoReloadCircleOutline } from "react-icons/io5";
import { FcCalendar } from "react-icons/fc";
import { useGetCustomerMeterVendHistoryMutation } from "../../../../services/salesanalytics.services";
import VendingHistoryTable from "../../../../components/SalesAgent/Table/VendingHistoryTable";
import { useGetAllMeterCustomersMutation } from "../../../../services/customer.services";
import { CustomerMeterProfile } from "../CustomerMeterProfile";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../controllers/errorBlock";
import {
  useUnassignMeterMutation,
  useUnassignRFMeterMutation,
} from "../../../../services/meter.services";
import toast from "react-hot-toast";
import {
  useBalanceEnquiryMutation,
  useImmediateTurnOffMutation,
  useImmediateTurnOnMutation,
} from "../../../../services/commands.services";

const MeterDetails = () => {
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "month").format("YYYY-MM-DD")
  );

  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const { customer, meterid } = useParams();
  const { siteid } = useSelector((state) => state.projectSite);

  const [
    getAllMeterCustomers,
    { isloading: customerloading, data: customerData, error: customerError },
  ] = useGetAllMeterCustomersMutation();

  const [
    getCustomerMeterVendHistory,
    { isLoading: vendLoading, data: vendData, error: vendError },
  ] = useGetCustomerMeterVendHistoryMutation();

  // customer meter details
  useEffect(() => {
    try {
      getAllMeterCustomers({
        column: "meter",
        columnparameter: meterid,
        siteid,
      }).unwrap();
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  // vending call
  useEffect(() => {
    try {
      getCustomerMeterVendHistory({
        meterid,
        startdate,
        enddate,
      }).unwrap();
    } catch (error) {
      errorBlock(error);
    }
  }, [startdate, enddate]);

  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const [
    unassignMeter,
    { isLoading: unassignLoading, error: unassignError, data: unassignData },
  ] = useUnassignMeterMutation();

  const [
    unassignRFMeter,
    {
      isLoading: unassignRFLoading,
      error: unassignRFError,
      data: unassignRFData,
    },
  ] = useUnassignRFMeterMutation();

  const handleUnassign = (item) => {
    if (!item?.body[0]?.meter_model)
      return toast.error("Can't fetch customer meter data.");
    try {
      const customerMetertype = item?.body[0].meter_model.split("-")[1];

      if (customerMetertype == "XG") {
        unassignMeter({
          meternumber: meterid,
          customerid: customer,
          address: item?.address,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      } else if (customerMetertype == "RF") {
        // console.log(item);
        unassignRFMeter({
          meternumber: meterid,
          customerid: customer,
          address: item?.address,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  const items = [
    {
      id: 1,
      label: "Customer Profile",
      link: `/meter-details/${customer}/${meterid}/customerprofile`,
    },
    {
      id: 2,
      label: "Meter Analytics",
      link: `/meter-details/${customer}/${meterid}/analytics`,
    },
    {
      id: 3,
      label: "Meter Controls",
      link: `/meter-details/${customer}/${meterid}/controls`,
    },
    {
      id: 4,
      label: "Command history",
      link: `/meter-details/${customer}/${meterid}/commands`,
    },
  ];
  const [tabstate, settabstate] = useState(1);

  const [
    balanceEnquiry,
    { isLoading: balanceLoading, data: balanceData, error: balanceError },
  ] = useBalanceEnquiryMutation();

  const handleBalance = () => {
    try {
      balanceEnquiry({
        meterid,
      })
        .then((res) => {
          if (res.error) {
            errorBlock(res.error);
            return;
          }

          if (res.data.response !== "success") {
            toast.error(res.data.error);
            return;
          }
          toast.success(res.data.response);
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  };

  //   useImmediateTurnOffMutation,
  // useImmediateTurnOnMutation,

  const [
    immediateTurnOff,
    { isLoading: offLoading, data: offData, error: offError },
  ] = useImmediateTurnOffMutation();
  const [
    immediateTurnOn,
    { isLoading: onLoading, data: onData, error: onError },
  ] = useImmediateTurnOnMutation();

  const handlePowerState = (e) => {
    try {
      if (e == false) {
        immediateTurnOff({
          meterid,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      } else {
        // console.log(item);
        immediateTurnOn({
          meterid,
        })
          .then((res) => {
            if (res.error) {
              errorBlock(res.error);
              return;
            }

            if (res.data.response !== "success") {
              // console.log(res.data.error);
              toast.error(res.data.error);
              return;
            }

            toast.success(res.data.response);
          })
          .catch((err) => {
            errorBlock(err);
          });
      }
    } catch (error) {
      errorBlock(error);
    }
  };

  return (
    <Layout>
      <div className="rounded-md ">
        <div className="bg-white rounded-sm p-2">
          <div className="flex rounded-sm px-2 items-center justify-between mb-4">
            <BackBtn text={"Go back"} />
            <div className="">
              <Button
                loading={unassignRFLoading}
                className="!bg-secondary !border-none !text-white"
                type=""
                onClick={() => handleUnassign(customerData ?? null)}
              >
                Unassign Meter
              </Button>
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="flex">
              <div className="h-[30px] mr-2 w-[20px] rounded bg-red-200"></div>
              <div className="font-semibold pt-1 text-[16px] ">
                Details of meter owned by{" "}
                <span className="text-secondary">
                  {customer[0].toUpperCase() + customer.substring(1)}
                </span>
              </div>
            </div>

            <DateRangePicker
              setEndDate={setenddate}
              setStartDate={setstartdate}
              refreshBtn={setRefresh}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            {/* {Array.from(Array(4)).map((i, index) => ( */}
            <div
              // key={index}
              className="border flex flex-col justify-between px-4 py-2 h-[130px] rounded-md"
            >
              <h1>Meter number</h1>
              <div
                onClick={() => alert("Meter id copied")}
                className="flex text-[14px] font-light cursor-pointer items-center p-2 bg-blue-50 rounded-xl w-fit"
              >
                <h1 className=" p-0 m-0 font-semibold pr-4">{meterid}</h1>
                <div className="">
                  <IoCopyOutline />
                </div>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="m-0 text-[12px]">Meter model</p>
                <p className="m-0 text-[12px] font-semibold">HSK-RF</p>
                {/* <Switch size="" checkedChildren="off" unCheckedChildren="on" /> */}
              </div>
            </div>
            <div className="border flex flex-col justify-between px-4 py-2 h-[130px] rounded-md">
              <div className="flex items-center justify-between">
                <h1>Power state of meter</h1>
                <Tooltip
                  title={"Get current power state"}
                  className="border-1 rounded-full cursor-pointer "
                >
                  <IoReloadCircleOutline size={20} color="" />
                </Tooltip>
              </div>
              <div
                onClick={() => alert("Meter id copied")}
                className="flex text-[14px] font-light cursor-pointer items-center p-2 bg-green-100 rounded-full w-fit"
              >
                <h1 className=" p-0 m-0 text-green-900 font-semibold">
                  Active
                </h1>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="m-0 text-[12px]">Last known state of meter</p>
                <Switch
                  onChange={handlePowerState}
                  size=""
                  unCheckedChildren="off"
                  checkedChildren="on"
                />
              </div>
            </div>
            <div className="border flex flex-col justify-between px-4 py-2 h-[130px] rounded-md">
              <h1>Update received from meter</h1>
              <div
                onClick={() => alert("Meter id copied")}
                className="flex text-[14px] font-light cursor-pointer items-center p-2 bg-blue-50 rounded-xl w-fit"
              >
                <h1 className=" p-0 m-0 font-semibold pr-4">{meterid}</h1>
                <div className="">
                  <IoCopyOutline />
                </div>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="m-0 text-[12px]">Last known state of meter</p>
                <Switch size="" checkedChildren="off" unCheckedChildren="on" />
              </div>
            </div>
            <div className="border flex flex-col justify-between px-4 py-2 h-[130px] rounded-md">
              <h1>Date meter was assigned</h1>
              <div
                onClick={() => alert("Meter id copied")}
                className="flex text-[14px] font-light cursor-pointer items-center p-2 bg-gray-50 rounded-xl w-fit"
              >
                <h1 className=" p-0 m-0 font-semibold pr-4">
                  {moment(Date.now()).format("lll")}
                </h1>
                <div className="">
                  <FcCalendar size={18} />
                </div>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p></p>
                {/* <p className="m-0 text-[12px]">Last known state of meter</p>
              <Switch size="" checkedChildren="off" unCheckedChildren="on" /> */}
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
        {/* Cending table  */}
        <div className="mt-4 rounded-sm bg-white p-2 ">
          <div className="flex items-center mb-4 justify-between">
            <h1>Vending history on meter</h1>
            <Button
              loading={balanceLoading}
              onClick={handleBalance}
              className="!bg-secondary !border-none !text-white"
              type=""
            >
              Request for balance
            </Button>
          </div>
          <div className="h-[500px]">
            <VendingHistoryTable
              data={vendData?.purchases ?? []}
              loading={vendLoading}
              // setdetails={setdetails}
              // setmodal={setmodal}
            />
          </div>
        </div>

        <div className="mt-4 bg-white p-2 rounded-sm">
          <div className="flex mb-2 p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
            {items.map((i, index) => (
              <div
                onClick={() => {
                  if (i.id == 1) {
                    settabstate(i.id);
                  } else {
                    settabstate(i.id);
                    navigate(i.link);
                  }
                }}
                className={
                  tabstate == i.id
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[14px]`
                }
              >
                {i.label}
              </div>
            ))}
          </div>
          {tabstate == 1 && <CustomerMeterProfile data={customerData} />}

          {tabstate != 1 && <Outlet />}
        </div>
      </div>
      <div className="h-[200px]"></div>
    </Layout>
  );
};

export default MeterDetails;
