import { toast } from "react-hot-toast";

export const errorBlock = (err) => {
  console.log("error block");
  if (err?.response) {
    return toast.error(err.response?.data?.response);
  } else {
    if (err?.data) {
      return toast.error(err.data?.response);
    } else {
      toast.error(err.message);
    }
  }
};
