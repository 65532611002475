export const pageTitle = (link) => {
  if (link == "/dashboard/systemadmin/projectsite") {
    return "All project sites";
  } else if (link == "/dashboard/salesagent") {
    return "Vend meter";
  } else if (link.includes("/meter-details")) {
    return "Meter details";
  } else if (link == "/dashboard/customers") {
    return "Search for meter";
  } else if (link == "/projectmanager/revenue") {
    return "Site Revenue";
  } else if (link.includes("/dashboard")) {
    return "Dashboard";
  } else if (link == "/revenue") {
    return "Your company revenue";
  } else if (link == "/discount") {
    return "Site Discount";
  } else if (link.includes("/dculocation/")) {
    return "DCU locations";
  } else if (link.includes("/salesagent/customer/meter/")) {
    return "Customer Meter";
  } else if (link == "/projectsite/consumption-analytics") {
    return "Consumption analytics";
  } else if (link == "/projectsite/location") {
    return "Project Site Meters";
  } else if (link == "/allchannels") {
    return "Channels";
  } else if (link == "/settings/profile") {
    return "Profile";
  } else if (link == "/projectsite") {
    return "Project sites";
  } else if (link.includes("/userroles/projectzone")) {
    return "Staff Roles";
  } else if (link == "/settings/dropdown") {
    return "Site configuration";
  } else if (link == "/dcu") {
    return "Devices (DCU)";
  } else if (link.includes("/meter/batch/")) {
    return "Batch meters";
  } else if (link.includes("/users")) {
    return "Staff";
  } else if (link == "/salesagent/customer") {
    return "Customer details";
  } else if (link == "/allmeters") {
    return "Company meters";
  } else if (link.includes("/tierplans")) {
    return "Fixed unit";
  } else if (link.includes("/paymentplan/edit/")) {
    return "Payment plan details";
  } else if (link == "/salesagent/revenue") {
    return "Sales records";
  } else if (link == "/manage-meter") {
    return "Customer Meters";
  } else if (link == "/paymentplan") {
    return "Dynamic plans";
  } else if (link == "/zonecustomers") {
    return "Customers in Site";
  } else if (link == "/wallet") {
    return "Wallet";
  } else if (link.includes("/roles")) {
    return "Roles";
  } else if (link == "/allmeters" || link == "/usermeters") {
  } else if (link == "/meterclass") {
    return "Customer class";
  }

  // case "Revenue":
  //   return <IoWallet size={21} color={"#6c6c6c"} />;
  // case "Meter Settings":
  //   return (
  //     <RiSettings5Fill
  //       size={21}
  //       color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
  //     />
  //   );
  // case "Settings":
  //   return (
  //     <RiSettings5Fill
  //       size={21}
  //       color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
  //     />
  //   );
  // case "Sales Analytics":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/salesanalytics/") ? "#fff" : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Recharge History":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/recharge-history") ? "#fff" : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Meter Activities":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/customer-meter/meteractivities")
  //           ? "#fff"
  //           : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Transactions":
  //   return (
  //     <GiWallet
  //       size={21}
  //       color={
  //         location.pathname.includes("/allcustomer-transactions")
  //           ? "#fff"
  //           : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Log out":
  //   return <IoLogOut size={21} color={"#6c6c6c"} />;
  // default:
  //   return null;
  //   }
};
