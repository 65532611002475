import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TableTop from "../../../components/TableTop";
import axios from "../../../utils/axios";
import { MdElectricMeter } from "react-icons/md";
import DateRangePicker from "../../../components/DateRangePicker";
import AssignMeter from "../../../components/Meter/modals/AssignMeter";
import AllMeterTable from "../../../components/Meter/Table/AllMeterTable";
import { meterController } from "../../../controllers/MeterController";
import { useSelector } from "react-redux";
import AllBatchMeterTable from "../../../components/Meter/Table/AllBatchMeterTable";
import { generalController } from "../../../controllers/generalController";
import { errorBlock } from "../../../controllers/errorBlock";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import BatchMeterTable from "../../../components/Meter/Table/BatchMeterTable";
import BackBtn from "../../../components/BackBtn";
import { Input } from "antd";
const BatchMeters = () => {
  const { batchid } = useParams();
  const user = useSelector((state) => state.userDetails);
  const [count, setCount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [decommissionedModal, setDecommissionModal] = useState(false);
  const [commissionedModal, setCommissionModal] = useState(false);
  const [registeredModal, setRegisteredModal] = useState(false);
  const [data, setData] = useState([]);
  const [batch, setbatch] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [tabstate, settabstate] = useState(2);
  const [meterstats, setmeterstats] = useState({
    no_assigned_meters: 0,
    no_out_of_service_meters: 0,
    no_unique_devices: 0,
    total_count: 0,
  });

  // get all meter by batch
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmetersbybatchid", {
          batch: batchid,
        });
        const result = generalController(res);

        // console.log(result.message.body);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        setbatch(result.message.body);
        setmeterstats({
          no_assigned_meters: result.message.meta.no_assigned_meters,
          no_out_of_service_meters:
            result.message.meta.no_out_of_service_meters,
          no_unique_devices: result.message.meta.no_unique_devices,
          total_count: result.message.meta.total_count,
        });
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);

  const [meterinput, setmeterinput] = useState("");

  const filteredData = batch.filter(
    (i) =>
      i?.assignedto.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.batchid.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.phase_type.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.device_serial
        .toLowerCase()
        .trim()
        .includes(meterinput.toLowerCase()) ||
      i?.type.toLowerCase().trim().includes(meterinput.toLowerCase()) ||
      i?.meternumber.trim().includes(meterinput.toLowerCase()) ||
      String(i?.loadlimit).trim().includes(meterinput)
  );

  return (
    <Layout>
      {addMeterModal && <AssignMeter closeModal={setAddMeterModal} />}
      <div
        className="mt-2 p-2 bg-white h-auto shadow-md rounded-md"
        style={{
          marginBottom: 200,
        }}
      >
        <div className="pt-2">
          <BackBtn />
        </div>
        <div className="w-full ">
          <div className="p-1 flex items-center justify-between mb-3">
            <div className="flex items-center ">
              <div className=" w-[15px] h-[25px] bg-[#00ffea5d] rounded-sm"></div>
              <div className="font-semibold ml-2">
                Meter stats for batch:
                <span className="pl-1">{batchid}</span>
              </div>
            </div>
            <div className="">
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 p-2 md:grid-cols-4 lg:grid-cols-4 my-1 gap-4">
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=" text-sky-500"
                  className=" text-green-600"
                />
              </div>
              <div className="pt-2 font-bold">Total Meter in batch.</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.total_count ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter size={35} color="" className=" text-sky-500" />
              </div>
              <div className="pt-2 font-bold">Number of assigned meters.</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.no_assigned_meters ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=""
                  className=" text-gray-600"
                />
              </div>
              <div className="pt-2 font-bold">Number of unique device</div>
              <div className="pt-1 font-bold text-3xl">
                {meterstats.no_unique_devices ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=""
                  className=" text-orange-500"
                />
              </div>
              <div className="pt-1 font-bold">Number of meter out service</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.unassigned ?? 0}
              </div>
            </div>
          </div>

          <div className="row mt-1 rounded-md">
            <div className="flex items-center justify-between">
              <TableTop
                title={"All meters in batch"}
                showAddMeter={true}
                setAddMeterModal={setAddMeterModal}
              />
              <Input.Search
                size=""
                style={{
                  width: 250,
                }}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setmeterinput(e.target.value);
                }}
                placeholder="Search for a meter"
                className=" placeholder:text-[12px] placeholder:font-light "
              />
            </div>
            <div className="h-auto overflow-y-hidden overflow-x-auto">
              <BatchMeterTable data={filteredData} />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[300px] w-full"></div>
    </Layout>
  );
};

export default BatchMeters;
