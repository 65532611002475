export const controlsData = {
  enquiryData: [
    {
      id: 1,
      label: "System Average Interruption Duration Index",
      short: "SAIDI",
      description:
        "SAIDI measures the average total duration of power interruptions experienced by a customer over a specific period",
    },
    {
      id: 2,
      label: "System Average Interruption Frequency Index",
      short: "SAIFI",
      description:
        "SAIFI measures the average number of interruptions that a customer experiences in a given period",
    },
  ],
  hardwareCommands: [
    // {
    //   id: 1,
    //   label: "Clear Command",
    //   short: "CLR",
    //   description:
    //     "Executes a command to clear a specific operation on a meter.",
    //   transactionTopic: "/commands/clearexistingcommandonmeter",
    // },
    {
      id: 2,
      short: "CTP",

      label: "Clear Hardware Tamper",
      description: "Clears hardware tamper status on a meter.",
      transactionTopic: "/commands/clearhardwaretamper",
    },
    {
      id: 3,
      label: "Get Hardware Tamper Status",
      short: "GTP",
      description: "Retrieves the hardware tamper status of a device.",
      transactionTopic: "/commands/gethardwaretamperstatus",
    },
  ],
  settingsCommands: [
    {
      id: 1,
      label: "Reset SAIDI",
      description:
        "Resets the System Average Interruption Duration Index (SAIDI) on a device.",
      transactionTopic: "/commands/resetsaidi",
      short: "RST03",
    },
    {
      id: 2,
      label: "Reset SAIFI",
      description:
        "Resets the System Average Interruption Frequency Index (SAIFI) on a device.",
      transactionTopic: "/commands/resetsaifi",
      short: "RST04",
    },
  ],
};
