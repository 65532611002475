import moment from "moment";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "../Button";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";
import { tableCustomStyles } from "../../helperFuncs";

const DropdownTable = ({ dropdowns, loading }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    {
      name: "Name",
      center: true,
      selector: (row) => row.label,
      grow: 1,
    },
    {
      name: "Items",
      center: true,
      selector: (row) => row.items,
      grow: 5,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.date,
      grow: 2,
      // cell: (item) => {
      //   return (
      //     <div className="flex items-center">
      //       <div className="px-1">
      //         <FcCalendar size={18} />
      //       </div>
      //       <div className="text-[11px]">{moment(item.date).format("lll")}</div>
      //     </div>
      //   );
      // },
      sortable: true,
    },
    // {

    //   name: "Action",
    //   selector: (row) => row.name,
    //   grow: 2,
    //   cell: (item) => {
    //     return (
    //       <div className="flex relative rounded-md items-center justify-between">
    //         <div
    //           onClick={() => {
    //             // setselectedSite(item.sitename);
    //             // setDeleteSite(true);
    //           }}
    //           className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
    //         >
    //           Delete
    //         </div>
    //         <div
    //           // onClick={() => setEditSite(item)}
    //           className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
    //         >
    //           Edit
    //         </div>
    //         <Link
    //           // to={`/dashboard/systemadmin/projectsite/${item.sitename}`}
    //           className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
    //         >
    //           Details
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={dropdowns}
      pagination
      progressPending={loading}
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default DropdownTable;
