import { Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CustomerMeterChart from "../../../../components/Meter/Charts/CustomerMeterChart";
import { useGetEnergyAnalyticsSummaryMutation } from "../../../../services/analytics.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useParams } from "react-router-dom";
import moment from "moment";
export const CustomerMeterAnalytics = () => {
  const [
    getEnergyAnalyticsSummary,
    { isloading: meterloading, data: meterData, error: meterError },
  ] = useGetEnergyAnalyticsSummaryMutation();

  const { customer, meterid } = useParams();
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD hh:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")
  );
  useEffect(() => {
    try {
      getEnergyAnalyticsSummary({
        meterjsonarray: JSON.stringify([meterid]),
        startdate,
        enddate,
      })
        .unwrap()
        .catch((err) => {
          errorBlock(err);
        });
    } catch (error) {
      errorBlock(error);
    }
  }, []);

  console.log(meterData, "meter data");
  // useEffect(() => {
  //   if (meterError) {
  //     console.log(meterError, "MEter error");
  //     errorBlock(meterError);
  //   }
  // }, []);

  return (
    <div className="overflow-hidden">
      <div className="flex items-center justify-between">
        <h1>Meter consumption analytics</h1>
        <Tooltip
          color=""
          style={{
            color: "black !important",
          }}
          className="!text-black"
          title="Request for consumption in realtime"
        >
          <Button className="!bg-secondary !border-none !text-white" type="">
            Realtime consumption Analytics
          </Button>
        </Tooltip>
      </div>
      <div className="h-[600px]">
        <CustomerMeterChart data={[]} />
      </div>
    </div>
  );
};
