import React, { useState } from "react";
import Rule from "../../../../components/Rule/Index";
import { Button, Input } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEditCustomerMeterMutation } from "../../../../services/customer.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export const CustomerMeterProfile = ({ data }) => {
  const { customer, meterid } = useParams();
  const [editable, setEditable] = useState(false);

  const customerDetails = data?.body[0];

  const initialFormState = {
    address: customerDetails?.address,
    gps: customerDetails?.gps,
    polenumber: customerDetails?.polenumber,
    section: customerDetails?.section,
    // projectzone: customerDetails?.projectzone || "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const { siteid } = useSelector((state) => state.projectSite);

  const [
    editCustomerMeter,
    { isLoading: customerLoading, data: customerData, error: customerError },
  ] = useEditCustomerMeterMutation();

  // Handle input changes dynamically
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle the Edit Customer action
  const handleEditCustomer = async () => {
    try {
      const response = await editCustomerMeter({
        customerid: customer,
        meter: meterid,
        ...formState,
        customertype: customerDetails?.customertype,
        projectzone: siteid,
        // projectzone: Number(formState.projectzone), // Ensure projectzone is a number
      });

      if (response?.error) {
        errorBlock(response.error);
        return;
      }

      if (response?.data?.response !== "success") {
        toast.error(response?.data?.error || "Unknown error occurred.");
        return;
      }

      toast.success("Customer updated successfully!");
      setEditable(false);
    } catch (error) {
      errorBlock(error);
    }
  };

  return (
    <div className="grid grid-cols-5 h-auto gap-4">
      <div className="col-span-3 p-2">
        <div className="flex items-center justify-between">
          <h1>Customer Details</h1>
          <div className="">
            {!editable && (
              <Button type="default" onClick={() => setEditable(true)}>
                Edit
              </Button>
            )}
            {editable && (
              <>
                <Button
                  type="primary"
                  className="mx-2"
                  onClick={handleEditCustomer}
                  disabled={customerLoading}
                >
                  {customerLoading ? "Saving..." : "Save"}
                </Button>
                <Button
                  type="default"
                  className=""
                  onClick={() => {
                    setEditable(false);
                    setFormState(initialFormState); // Reset form to initial state
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
        <Rule />
        {/* Input fields dynamically mapped */}
        {[
          { label: "Address", field: "address" },
          { label: "GPS", field: "gps" },
          { label: "Pole Number", field: "polenumber" },
          { label: "Section", field: "section" },
          // { label: "Customer Type", field: "customertype" },
          // { label: "Project Zone", field: "projectzone" },
        ].map((input) => (
          <div
            className="flex items-center justify-between mb-2"
            key={input.field}
          >
            <p className="m-0">{input.label}</p>
            <Input
              className="!w-fit inline-block rounded-md"
              value={formState[input.field]}
              disabled={!editable}
              onChange={(e) => handleInputChange(input.field, e.target.value)}
            />
          </div>
        ))}
        {/* Static Fields */}
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Username</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customer}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Full Name</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={`${customerDetails?.firstname || ""} ${
              customerDetails?.lastname || ""
            }`}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Agent</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customerDetails?.agent || ""}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Project Site</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customerDetails?.sitename || ""}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Customer type</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customerDetails?.customertype || ""}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Meter</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={customerDetails?.meter || ""}
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="m-0">Date Created</p>
          <Input
            className="!w-fit inline-block rounded-md"
            value={moment(customerDetails?.datecreated).format("lll") || ""}
            disabled={true}
          />
        </div>
      </div>
      <div className="col-span-2 bg-grey rounded-md overflow-hidden">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255154.46837847016!2d30.00168340037021!3d-2.2330173214502875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dcac97382c6ee1%3A0x1585353879f45d73!2sBugesera!5e0!3m2!1sen!2srw!4v1734019968857!5m2!1sen!2srw"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};
