import React from "react";
import MeterCommandsTable from "../../../../components/Meter/Table/MeterCommandsTable";

export const CustomerMeterCommands = () => {
  return (
    <div className="">
      <p>Recent commands issued on meters</p>
      <MeterCommandsTable />
    </div>
  );
};
