import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
const TopSalesAgent = ({ data, currency }) => {
  const filterData = !currency ? [] : data ? data[currency] : [];

  const columns = [
    {
      name: "Name",
      center: true,
      selector: (row) => row.name,
      grow: 1,
      cell: (item) => {
        return (
          <div className="">
            {!item?.firstName
              ? "-N-"
              : `${item?.firstName[0].toUpperCase()}${item?.firstName.substring(
                  1
                )} ${item?.lastName[0].toUpperCase()}${item?.lastName.substring(
                  1
                )}`}
          </div>
        );
      },
    },
    // {
    //   name: "Project site",
    //   center: true,
    //   selector: (row) => row.project,
    //   grow: 1,
    // },

    {
      name: "Amount",
      center: true,
      sortable: true,
      selector: (row) => row.totalRevenue,
      grow: 1,
      cell: (item) => {
        return (
          <div className="">
            {Number(item?.totalRevenue).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
      //
    },
    {
      name: "Action",
      center: true,
      sortable: true,
      selector: (row) => row.amount,
      grow: 1,
      cell: (item) => {
        return (
          <Link
            to={`/dashboard/salesagent/${item.siteid ?? 2}/${item?.username}`}
            className="p-1 border-[1px] border-blue-400 text-blue-400 rounded-sm mr-3 cursor-pointer"
          >
            View dashboard
          </Link>
        );
      },
    },
  ];
  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={filterData}
      pagination={false}
      paginationPerPage={10}
      highlightOnHover
    />
  );
};

export default TopSalesAgent;
