import EmptyState from "../../../EmptyState";
import { HiUserGroup } from "react-icons/hi";
import moment from "moment";
import { toast } from "react-hot-toast";
import { channelController } from "../../../../controllers/channelController";
import { Button } from "../../../Button";
import axios from "../../../../utils/axios";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";
import { useState } from "react";
import { tableCustomStyles } from "../../../../helperFuncs";
import AssignMeter from "../../../Meter/modals/AssignMeter";
import { useSelector } from "react-redux";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { Link } from "react-router-dom";

const CustomerWithoutMeteTable = ({ data, refreshBtn }) => {
  const [username, setUsername] = useState("");
  const { role } = useSelector((state) => state.userDetails);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setuser] = useState(null);
  const [msg, setMsg] = useState("");
  const [assignmodal, setassignmodal] = useState(false);

  const columns = [
    {
      name: "Username",
      center: true,
      selector: (row) => row.customerid,
      grow: 3,
    },
    {
      name: "Full name",
      selector: (row) => row.email,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return <div className="">{`${item.firstname} ${item.lastname}`}</div>;
      },
    },
    {
      name: "Customer type",
      selector: (row) => row.customertype,
      center: true,
      sortable: true,
      grow: 2,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      center: true,
      grow: 2,
    },
    {
      name: "Agent",
      selector: (row) => row.agent,
      center: true,
      grow: 1,
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 3,
      center: true,
      hideomit: role !== "Customer Manage" ? false : true,
      cell: (item) => {
        return (
          // <div
          //   onClick={() => {
          //     setassignmodal(true);
          //     setuser(item);
          //   }}
          //   className="border-[1px] cursor-pointer text-secondary rounded border-secondary text-[12px] font-light px-4 py-2"
          // >
          //   Assign meter
          // </div>
          <div className="flex rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        className="text-[12px]"
                        // onClick={() => {
                        //   setSelectedId(item.meter);
                        //   setModal(true);
                        // }}
                      >
                        View details
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="text-[12px]"
                        // onClick={() => {
                        //   setediting(true);
                        //   handleUnassign(item);
                        // }}
                      >
                        Unassign meter
                      </div>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <Link
                        to={`/salesagent/customer/meter/${item?.meter}/${item?.customerid}`}
                        className="text-[12px]"
                        onClick={() => {}}
                      >
                        View Recharge history
                      </Link>
                    ),
                  },
                ],
              }}
              placement="bottom"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {assignmodal && (
        <AssignMeter
          refresh={null}
          setopen={setassignmodal}
          open={assignmodal}
          user={user}
        />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </>
  );
};

export default CustomerWithoutMeteTable;
