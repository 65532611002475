import "./style.css";
import React, { useRef, useState, useEffect } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoLogOut } from "react-icons/io5";
import { deleteToken, getToken } from "../../utils/token";
import { FiLogOut } from "react-icons/fi";
import SideBarFooter from "./Footer";
import { IoIosArrowDown } from "react-icons/io";
import toast from "react-hot-toast";
import { data } from "./data";
import NavIcon from "./NavIcon";
import { themeColor } from "../../constant/color";
import { postLogout } from "../../redux/actions/auth.action";
import { channelController } from "../../controllers/channelController";
import axios from "../../utils/axios";
import Skimmer from "../Loader/Skimmer";
import AppLogo from ".././../assets/png/applogo.jpeg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useGetUserRolesMutation } from "../../services/roles.services";
import { errorBlock } from "../../controllers/errorBlock";

const SideNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [mouse, setMouse] = useState(false);
  const navigate = useNavigate();
  const handlenavItems = () => {
    setMouse((prev) => true);
  };
  const handleMouseOut = () => {
    setMouse((prev) => false);
  };
  const navLink = useRef();
  const [name, setName] = useState("AK");
  const [fullName, setfullName] = useState("");
  const [settingsModal, setSettingsModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const { role, username, roleCount } = useSelector(
    (state) => state.userDetails
  );
  const [meterModal, setMeterModal] = useState(false);
  const [selectedTab, setselectedTab] = useState("");
  const [loadingmeter, setloadingmeter] = useState(true);
  const [showRoles, setShowRoles] = useState(true);
  const [meter, setmeter] = useState([]);

  return (
    <div className="side-nav relative hidden md:hidden px-1 lg:block side-nav_bg h-screen w-[220px] xl:w-[270px] drop-shadow-md bg-gray-900">
      <div className="mt-8 flex items-end pb-2 px-6">
        <div
          style={{
            backgroundImage: `url(${AppLogo})`,
            backgroundPosition: "40%", //to position the background image
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="flex w-[27px] h-[27px] overflow-hidden rounded-full "
        ></div>
        <div className="flex mb-[-1px] items-start justify-center  flex-col">
          <div className="font-bold pl-2 text-[16px] leading-[14px] text-white">
            Chanels
          </div>
          {/* <hr className="border-gray-200 border-[0.2px] w-[20%]" /> */}
          <div className="pl-2 capitalize text-[8px] text-gray-200">
            management system
          </div>
        </div>
      </div>
      <div className="pt-3 h-[80%] overflow-x-hidden overflow-y-scroll">
        {role == "Customer Representative" && (
          <Tree treeData={data.customerRep} />
        )}
        {role == "System Manager" && <Tree treeData={data.sysdmin} />}
        {role == "Project Manager" && <Tree treeData={data.projectmanager} />}
        {role == "Sales Agent" && <Tree treeData={data.salesagent} />}
        {role == "Customer Manager" && <Tree treeData={data.customerManager} />}
        {role == "Customer" && <Tree treeData={data.customers} />}
        {/* bg-[#263146] */}
        <div
          onClick={() => {
            // window.open("http://localhost:5174/dashboard");
            window.open("https://sanaa.chanels.io/dashboard");
          }}
          className="px-5 cursor-pointer rounded-sm flex items-center justify-between hover:bg-[#263146]  py-3 relative"
        >
          <div className="text-white">
            Chat with{" "}
            <span className="inline-block font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#FFA825] to-[#FF00F4]">
              Sanaa
            </span>{" "}
          </div>
          <div className=" text-white py-[2px] px-[4px] text-[9px] rounded-md bg-blue-600 right-2 top-[-10px]">
            New
          </div>
        </div>
        <div className="h-[200px]"></div>
      </div>
      <div
        className="absolute bottom-6 cursor-pointer w-full"
        onClick={async () => {
          try {
            dispatch(postLogout());
          } catch (err) {
            toast.err(err.message);
          }
        }}
      >
        <div className="flex pl-4">
          <div className="flex items-center justify-center pt-[2px]">
            <FiLogOut size={16} color="#a3a3a3" />
          </div>
          <div className="text-[14px] font-normal text-[#a3a3a3] pl-4 ">
            Log out
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

const Tree = ({ treeData }) => {
  return (
    <>
      {treeData.map((node, i) => (
        <TreeNode node={node} key={i} />
      ))}
    </>
  );
};

const TreeNode = ({ node }) => {
  const { children, title, icon, link } = node;
  const location = useLocation();
  const navigate = useNavigate();
  const [showChildren, setShowChildren] = useState(false);
  const handleClick = () => {
    setShowChildren(!showChildren);
  };
  const [loading, setLoading] = useState(true);
  const [activelink, setactivelink] = useState("");
  const { role, roleCount } = useSelector((state) => state.userDetails);
  const { sitename } = useSelector((state) => state.projectSite);

  return (
    <>
      <div
        onClick={() => {
          setactivelink(title);
          if (children.length == 0) {
            navigate(
              role == "Customer Representative" && title == "Dashboard"
                ? `${link}/${sitename}`
                : role == "Sales Agent" && title == "Dashboard"
                ? `${link}`
                : link.replace(":sitename", sitename)
            );
          } else {
            handleClick();
          }
        }}
        className={`${
          roleCount == 1 && link == "/userroles" ? "hidden" : "flex"
        }
        justify-between ${
          link == location.pathname ||
          activelink == "Payment plan" ||
          activelink == "Settings"
            ? "border-l-[2px] border-white bg-[#263146]"
            : "bg-[transparent]"
        } items-start px-4 py-3 my-3 cursor-pointer hover:bg-[#263146]`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center pt-[2px]">
            <NavIcon title={title} link={link} />
          </div>
          <div
            className="text-[15px] font-light pl-4 hover:text-white "
            style={{
              color:
                location.pathname == link || activelink == "Payment plan"
                  ? themeColor.white
                  : "#a3a3a3",
              display:
                title.trim() == "All user roles" &&
                role.trim() !== "System Manager"
                  ? "none"
                  : "block",
            }}
          >
            {title}
          </div>
        </div>
        {children.length != 0 && (
          <div className="flex iitems-center justify-center pt-1">
            <MdKeyboardArrowDown className={`text-[#a3a3a3]`} size={20} />
          </div>
        )}
      </div>
      {showChildren && <Tree treeData={children} />}
    </>
  );
};
